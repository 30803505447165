<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" title="Attention" no-close-on-backdrop>
        <div class="text-center text-danger">
            <font-awesome-icon icon="exclamation-triangle" class="huge-icon"/>
        </div>
        <p class="mb-3 mt-3">
            Le bureau est autorisé à accéder à cette page pour des raisons pratiques (si un webcrétaire n'est pas
            disponible ou pas existant cette année), mais il est déconseillé de toucher à quoi que ce soit si tu n'es
            pas sûr de savoir ce que tu fais.
        </p>
        <div class="text-center">
            <b-button @click="hideModal">Je sais ce que je fais</b-button>
        </div>
    </b-modal>
</template>

<script>
    import modalMixin from '@/mixin/modalMixin';

    export default {
        name: "WebcretariumWarningModal",
        mixins: [modalMixin],
        data: () => ({
            modalRef: 'webcretariumWarningModal'
        })
    }
</script>

<style scoped>
    .huge-icon {
        font-size: 72px;
    }
</style>